import styles from "./ourPricing.module.css";
import React from "react";
import SectionTitle from "../../common/sectionTitle/SectionTitle";

const pageInfos = {
  firstTitle: "OUR",
  secondTitle: "PRICING",
  description:
    "We won’t tie you into a contract or charge you a fortune every month. So make yourself at home and take a look around.",
};

const prices = [
  {
    title: "6 Week Transformation Program",
    price: "£ 147",
    month: "6 WEEKS",
    body: ["Transform your body and mind with our most popular program!",
    <p><span style={{ fontWeight: 700}}>Unlimited Sessions: </span> Maximize your results with as many sessions as you need in 6 weeks.</p>,
    <p><span style={{ fontWeight: 700}}>Comprehensive Nutrition Support: </span> Includes personalized meal plans, recipes, and expert guidance to fuel your success.</p>,
    <p><span style={{ fontWeight: 700}}>Weekly Educational Modules: </span> Gain the knowledge to stay consistent and motivated.</p>,
    <p><span style={{ fontWeight: 700}}>Success Manuals & Accountability Coaching: </span> Get the tools and support to crush your goals.</p>,
    ],
  },
  {
    title: "Inner Circle Membership",
    price: "£ 97",
    month: "MONTHLY",
    body: ["The perfect continuation after the transformation program!",
      <p><span style={{ fontWeight: 700}}>Unlimited Monthly Sessions: </span> Stay consistent and maintain your momentum.</p>,
      <p><span style={{ fontWeight: 700}}>Ongoing Nutrition & Accountability Support: </span> Tailored to keep you on track long-term.</p>,
      <p><span style={{ fontWeight: 700}}>No Contracts: </span> Only £97/month. Keep your membership as long as it works for you!</p>,
      "Stay committed. Stay strong. Stay unstoppable."],
  },
  {
    title: "10 Session Package",
    price: "£ 120",
    month: "60 Days",
    body: ["For those looking for flexibility",
    <p><span style={{ fontWeight: 700}}>10 Sessions: </span> Ideal if you need a lighter commitment.</p>,
    <p><span style={{ fontWeight: 700}}>Expires in 60 Days: </span> Use your sessions within two months from the first session.</p>,
    ],
  },
];

const OurPricing = () => {
  return (
    <div className={styles.container}>
      <SectionTitle pageInfos={pageInfos} />
      <div className={styles.content}>
        {prices.map((price, i) => (
          <div key={i} className={styles.item}>
            <div style={{display: "flex", gap: "10px", alignItems: "center", justifyContent: "center", padding: "10px 40px"}}>
            <p style={{width: "50%"}} className={styles.title}>{price.title}</p>
            
              <span style={{width: "30%"}} className={styles.price}>{price.price}</span>
              <p style={{width: "20%"}} className={styles.month}>{price.month}</p>
            
            </div>
           {price.body.length > 0 && <div className={styles.body}>
              <div>
                {price.body.map((item, i) => (
                  <p key={i}>{item}</p>
                ))}
              </div>
            </div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurPricing;
